@import url(https://use.fontawesome.com/releases/v5.1.0/css/all.css);
@import url(https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i);
@font-face {
  font-family: 'Open sans';
  src: url("/fonts/opensans-regular-webfont.woff2") format("woff2"), url("/fonts/opensans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("/fonts/opensans-semibold-webfont.woff2") format("woff2"), url("/fonts/opensans-semibold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("/fonts/opensans-light-webfont.woff2") format("woff2"), url("/fonts/opensans-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

:root {
  --first: #2979ff;
  --launcher: #2979ff;
  --bg-back: #fff;
  --border-color: #E2E2E2;
  --titles: #333;
  --gray: #666;
  --gray-light: #ddd;
  --gray-dark: #999;
  --error: #dd2c00;
  --bubble-bg: #f5f5f5;
  --bubble-bg-message: #0078c7;
  --bubble-bg-message-text: #9a9a9f;
  --bubble-bg-message-text-send: #FFFFFF;
  --white-gray: #f8f8f8;
  --success: #86c546;
  --gradient-medium: var(--first);
  --gradient-dark: var(--first); }

/*----Breakpoints----*/
@-webkit-keyframes main_cognitive-assistant-fade-in-up__1bYGU {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); } }
@keyframes main_cognitive-assistant-fade-in-up__1bYGU {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); } }

@-webkit-keyframes main_spinner__QtqCQ {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes main_spinner__QtqCQ {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes main_pulso__3BbPv {
  0% {
    width: 0;
    height: 0; }
  50% {
    width: 2rem;
    height: 2rem;
    margin: -1rem 0 0 -1rem;
    opacity: 1; }
  100% {
    width: 3.2rem;
    height: 3.2rem;
    margin: -1.6rem 0 0 -1.6rem;
    opacity: 0; } }

@keyframes main_pulso__3BbPv {
  0% {
    width: 0;
    height: 0; }
  50% {
    width: 2rem;
    height: 2rem;
    margin: -1rem 0 0 -1rem;
    opacity: 1; }
  100% {
    width: 3.2rem;
    height: 3.2rem;
    margin: -1.6rem 0 0 -1.6rem;
    opacity: 0; } }

@-webkit-keyframes main_in__3Ptty {
  0% {
    opacity: 1; }
  50% {
    opacity: .5; }
  100% {
    opacity: 0; } }

@keyframes main_in__3Ptty {
  0% {
    opacity: 1; }
  50% {
    opacity: .5; }
  100% {
    opacity: 0; } }

@-webkit-keyframes main_inAssistant__InHWm {
  0% {
    opacity: 0; }
  50% {
    opacity: .5; }
  100% {
    opacity: 1; } }

@keyframes main_inAssistant__InHWm {
  0% {
    opacity: 0; }
  50% {
    opacity: .5; }
  100% {
    opacity: 1; } }

@-webkit-keyframes main_notification__2ikcN {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes main_notification__2ikcN {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

html {
  font: 300 62.5% "Geomanist"; }

* {
  margin: 0;
  box-sizing: border-box; }

body {
  font-family: "Lato", sans-serif;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  font-size: 14px; }

h3 {
  font-family: "Lato", sans-serif;
  font-size: 1.5rem; }

.main_Hide__1ks7T {
  opacity: 0.3; }

.main_Disabled__6OnfT {
  opacity: 0.3;
  pointer-events: none; }

input,
select,
textarea,
button {
  font-family: "Lato", sans-serif; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

input,
select,
textarea {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  border: 0 none;
  padding: 1rem;
  transition: all 300ms ease;
  height: 4.5rem;
  font-size: 1.4rem; }
  input:hover,
  select:hover,
  textarea:hover {
    border-color: #333;
    border-color: var(--titles); }
  input:focus,
  select:focus,
  textarea:focus {
    outline: 0 none; }
  input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    -webkit-text-fill-color: #666 !important;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s; }

.main_ButtonReset__hvlUv, .main_Btn__1VyIx {
  -webkit-appearance: none;
  appearance: none;
  background: #f42534;
  border-radius: 0px;
  border: 0px;
  outline: 0px;
  padding: 0px; }

.main_Btn__1VyIx {
  cursor: pointer;
  padding: 9px 20px 10px;
  width: auto;
  height: auto;
  min-width: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease;
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0 0.4rem;
  color: #fefffc;
  transition: all 0.35s ease;
  border-radius: 5px; }
  .main_Btn__1VyIx:hover {
    color: #fff;
    color: var(--bg-back); }
  .main_Btn__1VyIx.main_Rounded__1Gchr {
    border-radius: 18px; }
  .main_Btn__1VyIx.main_BtnTransparent__CLEU1 {
    background: transparent;
    border: none; }
    .main_Btn__1VyIx.main_BtnTransparent__CLEU1:hover {
      background: transparent; }
  .main_Btn__1VyIx i {
    margin-left: 0.4rem;
    font-size: 1rem; }

.main_MainLauncher__xR3q5 {
  width: 100%;
  max-width: 200px;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 99999999;
  -webkit-animation-name: main_cognitive-assistant-fade-in-up__1bYGU;
          animation-name: main_cognitive-assistant-fade-in-up__1bYGU;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  transition: all 200ms linear; }
  .main_MainLauncher__xR3q5 .main_LauncherButton__2AjXm {
    border: 0 none;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color: var(--laucher);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    margin-left: 68%; }
    .main_MainLauncher__xR3q5 .main_LauncherButton__2AjXm:hover {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    .main_MainLauncher__xR3q5 .main_LauncherButton__2AjXm:focus {
      outline: 0 none; }
    .main_MainLauncher__xR3q5 .main_LauncherButton__2AjXm i {
      transition: all 200ms linear;
      display: block;
      background-position: 50% 50%;
      background-repeat: no-repeat; }
      .main_MainLauncher__xR3q5 .main_LauncherButton__2AjXm i.main_IconClose__3TEHO {
        background-image: url(/static/media/icon-close.f0b5673a.svg);
        height: 0; }
    .main_MainLauncher__xR3q5 .main_LauncherButton__2AjXm.main_Close__pMXr8 {
      margin-left: 68%; }
      .main_MainLauncher__xR3q5 .main_LauncherButton__2AjXm.main_Close__pMXr8 .main_IconLauncher__uSZyc {
        height: 0rem; }
      .main_MainLauncher__xR3q5 .main_LauncherButton__2AjXm.main_Close__pMXr8 .main_IconClose__3TEHO {
        height: 3.3rem; }
  .main_MainLauncher__xR3q5 .main_BubbleLauncher__3ukv0 {
    width: 1.8rem;
    height: 1.8rem;
    position: absolute;
    top: 4.5rem;
    left: -0.5rem;
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
    color: #fff;
    background-color: #dd2c00;
    background-color: var(--error);
    border-radius: 100%; }
    .main_MainLauncher__xR3q5 .main_BubbleLauncher__3ukv0:after {
      content: "";
      border-radius: 50%;
      position: absolute;
      left: 45%;
      top: 45%;
      border: 1px solid #dd2c00;
      border: 1px solid var(--error);
      opacity: 0;
      -webkit-animation: main_pulso__3BbPv 0.8s infinite linear;
      animation: main_pulso__3BbPv 0.8s infinite linear; }
  .main_MainLauncher__xR3q5 .main_Notification__2eoQ7 {
    position: relative;
    bottom: 2rem;
    right: 85px;
    max-width: 28rem;
    width: 28rem;
    padding: 1.6rem;
    padding-right: 4.2rem;
    box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    -webkit-animation: main_notification__2ikcN 2s infinite linear;
            animation: main_notification__2ikcN 2s infinite linear;
    border-radius: 2.4rem 2.4rem 0 2.4rem;
    line-height: 1.8rem; }
    .main_MainLauncher__xR3q5 .main_Notification__2eoQ7 span {
      color: grey;
      font-size: 14px;
      font-weight: bold; }
    .main_MainLauncher__xR3q5 .main_Notification__2eoQ7 .main_Title__2C05O {
      font-weight: bold;
      font-size: 14px;
      color: #333; }
    .main_MainLauncher__xR3q5 .main_Notification__2eoQ7 .main_ButtonClose__1m-uM {
      display: block;
      border: 0 none;
      position: absolute;
      top: 10px;
      right: 10px;
      background: transparent;
      width: 24px;
      height: 24px;
      overflow: hidden;
      cursor: pointer;
      font-size: 14px;
      color: #333; }
      .main_MainLauncher__xR3q5 .main_Notification__2eoQ7 .main_ButtonClose__1m-uM .main_IconClose__3TEHO {
        background-image: url(/static/media/icon-close.f0b5673a.svg);
        width: 32px;
        height: 32px;
        display: inline-block;
        background-position: -44px 0px;
        margin: -3px 0 0 -2px; }

.main_MainAssistant__3dmqT {
  -webkit-animation: main_inAssistant__InHWm 0.4s linear;
          animation: main_inAssistant__InHWm 0.4s linear;
  overflow: hidden;
  border-radius: 0.7rem;
  box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.2);
  display: grid;
  margin: 6rem 1rem 0rem 1rem;
  grid-template-rows: 6rem calc(100vh - 20.7rem) 5.7rem;
  grid-template-columns: 6rem auto;
  grid-template-areas: "header header" "conversation conversation" "input input"; }
  .main_MainAssistant__3dmqT.main_HeaderMore__15yFO {
    height: calc(100vh - 9rem); }
  .main_MainAssistant__3dmqT .main_Header__1xX72,
  .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH {
    z-index: 2;
    grid-area: header;
    box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.3);
    -webkit-transform: translate3d(0, 0vh, 0);
            transform: translate3d(0, 0vh, 0);
    transition: all 200ms ease;
    grid-column: 1 / 3;
    background-color: #2979ff;
    background-color: var(--first);
    display: grid;
    grid-template-columns: 6rem auto 6rem;
    grid-template-rows: 5rem 3rem;
    align-items: center;
    overflow: hidden;
    height: 7.7rem; }
    .main_MainAssistant__3dmqT .main_Header__1xX72.main_Large__2227J:before,
    .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH.main_Large__2227J:before {
      content: "";
      background: linear-gradient(to top, white, rgba(0, 0, 0, 0));
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 50%; }
    .main_MainAssistant__3dmqT .main_Header__1xX72.main_HeaderMore__15yFO,
    .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH.main_HeaderMore__15yFO {
      grid-template-columns: 2rem calc(100% - 8.6rem) 5rem 1.6rem;
      grid-template-rows: 6rem auto;
      justify-content: space-between;
      height: 100%;
      box-shadow: none; }
      .main_MainAssistant__3dmqT .main_Header__1xX72.main_HeaderMore__15yFO::before,
      .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH.main_HeaderMore__15yFO::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -2; }
      .main_MainAssistant__3dmqT .main_Header__1xX72.main_HeaderMore__15yFO .main_HeaderImage__3vY1g,
      .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH.main_HeaderMore__15yFO .main_HeaderImage__3vY1g {
        justify-self: left; }
      .main_MainAssistant__3dmqT .main_Header__1xX72.main_HeaderMore__15yFO .main_HeaderText__3uYQk,
      .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH.main_HeaderMore__15yFO .main_HeaderText__3uYQk {
        grid-column: 2 / 4;
        align-self: start;
        padding: 0.5rem; }
        .main_MainAssistant__3dmqT .main_Header__1xX72.main_HeaderMore__15yFO .main_HeaderText__3uYQk h3,
        .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH.main_HeaderMore__15yFO .main_HeaderText__3uYQk h3 {
          font-weight: 300;
          font-size: 2.1rem;
          margin-bottom: 1rem; }
        .main_MainAssistant__3dmqT .main_Header__1xX72.main_HeaderMore__15yFO .main_HeaderText__3uYQk p,
        .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH.main_HeaderMore__15yFO .main_HeaderText__3uYQk p {
          opacity: 1;
          font-size: 2rem;
          font-weight: 300; }
    .main_MainAssistant__3dmqT .main_Header__1xX72 .main_HeaderImage__3vY1g,
    .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH .main_HeaderImage__3vY1g {
      width: 100%;
      margin-top: 2rem;
      max-width: 5rem;
      justify-self: center;
      object-fit: cover;
      border-radius: 100%;
      margin-left: 2rem; }
    .main_MainAssistant__3dmqT .main_Header__1xX72 .main_HeaderText__3uYQk,
    .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH .main_HeaderText__3uYQk {
      color: #fff;
      margin-top: 2rem;
      margin-left: 2rem;
      cursor: pointer;
      padding: 0.5rem;
      border-radius: 1rem;
      transition: all 0.3s ease-in-out; }
      .main_MainAssistant__3dmqT .main_Header__1xX72 .main_HeaderText__3uYQk:hover,
      .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH .main_HeaderText__3uYQk:hover {
        background: rgba(0, 0, 0, 0.1); }
      .main_MainAssistant__3dmqT .main_Header__1xX72 .main_HeaderText__3uYQk h3,
      .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH .main_HeaderText__3uYQk h3 {
        line-height: 1.6rem; }
      .main_MainAssistant__3dmqT .main_Header__1xX72 .main_HeaderText__3uYQk p,
      .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH .main_HeaderText__3uYQk p {
        opacity: 0.75;
        font-size: 1.1rem;
        font-weight: normal; }
    .main_MainAssistant__3dmqT .main_Header__1xX72 .main_BtnTransparent__CLEU1,
    .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH .main_BtnTransparent__CLEU1 {
      justify-self: center;
      transition: all 300ms ease-in-out;
      opacity: 1; }
      .main_MainAssistant__3dmqT .main_Header__1xX72 .main_BtnTransparent__CLEU1 .main_IconMinimized__24cBh,
      .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH .main_BtnTransparent__CLEU1 .main_IconMinimized__24cBh {
        position: absolute;
        left: 28.5rem;
        top: 2.2rem;
        cursor: pointer;
        background: white;
        width: 1.3rem;
        height: 0.1rem;
        transition: all 0.3s ease-in-out;
        border-radius: 2rem;
        opacity: 1;
        margin-bottom: 0; }
      .main_MainAssistant__3dmqT .main_Header__1xX72 .main_BtnTransparent__CLEU1:focus,
      .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH .main_BtnTransparent__CLEU1:focus {
        outline: 0 none; }
      .main_MainAssistant__3dmqT .main_Header__1xX72 .main_BtnTransparent__CLEU1.main_Disabled__6OnfT,
      .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH .main_BtnTransparent__CLEU1.main_Disabled__6OnfT {
        opacity: 0.4;
        pointer-events: initial; }
      .main_MainAssistant__3dmqT .main_Header__1xX72 .main_BtnTransparent__CLEU1 .main_CloseButton__3BSex,
      .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH .main_BtnTransparent__CLEU1 .main_CloseButton__3BSex {
        background-image: url(/static/media/icon-close.f0b5673a.svg);
        position: absolute;
        top: 1.2rem;
        left: 30.5rem;
        width: 2.5rem;
        height: 1.2rem;
        background-position: 50% 50%;
        background-size: 2.5rem; }
      .main_MainAssistant__3dmqT .main_Header__1xX72 .main_BtnTransparent__CLEU1.main_ButtonHelp__V220W,
      .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH .main_BtnTransparent__CLEU1.main_ButtonHelp__V220W {
        background-image: url(/static/media/icon-help.f5a8615e.svg);
        background-repeat: no-repeat;
        width: 6rem;
        height: 2.6rem;
        background-position: 0.6rem 0.5rem;
        background-size: 5.3rem;
        border-color: #f2f2f2;
        border: 1px solid;
        grid-column: -3/-1;
        grid-row: -3/ -1;
        margin-top: 3.2rem;
        margin-right: 4rem; }
        .main_MainAssistant__3dmqT .main_Header__1xX72 .main_BtnTransparent__CLEU1.main_ButtonHelp__V220W:hover,
        .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH .main_BtnTransparent__CLEU1.main_ButtonHelp__V220W:hover {
          color: #666; }
        .main_MainAssistant__3dmqT .main_Header__1xX72 .main_BtnTransparent__CLEU1.main_ButtonHelp__V220W:focus,
        .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH .main_BtnTransparent__CLEU1.main_ButtonHelp__V220W:focus {
          outline: 0 none; }
        .main_MainAssistant__3dmqT .main_Header__1xX72 .main_BtnTransparent__CLEU1.main_ButtonHelp__V220W.main_Active__3mnli,
        .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH .main_BtnTransparent__CLEU1.main_ButtonHelp__V220W.main_Active__3mnli {
          box-shadow: 0 0 1rem #fff; }
        .main_MainAssistant__3dmqT .main_Header__1xX72 .main_BtnTransparent__CLEU1.main_ButtonHelp__V220W.main_Disabled__6OnfT,
        .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH .main_BtnTransparent__CLEU1.main_ButtonHelp__V220W.main_Disabled__6OnfT {
          pointer-events: none; }
  .main_MainAssistant__3dmqT .main_HeaderHelpDown__3FvFH {
    grid-template-columns: 6rem auto 5rem; }
  .main_MainAssistant__3dmqT .main_HeaderHelpUp__j6Q8z {
    grid-template-columns: 6rem auto 3.6rem 3.6rem 1.5rem; }
  .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 {
    padding: 2rem 3rem;
    z-index: 1;
    grid-area: helper;
    font-size: 1.3rem;
    transition: left 300ms ease-in-out;
    grid-column: 1 / 3;
    grid-row: 2 / 2;
    background: linear-gradient(to right, #2979ff, #2979ff);
    background: linear-gradient(to right, var(--gradient-medium), var(--first));
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    left: 100%;
    height: 70%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3); }
    .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4.main_Active__3mnli {
      left: 0%; }
      .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4.main_Active__3mnli + .main_MainAssistant__3dmqT {
        grid-template-areas: "header header" "helper helper" "input input"; }
    .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 h3 {
      color: #eee;
      font-size: 2.2rem;
      font-weight: 500; }
    .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 p {
      font-size: 1.4rem;
      font-weight: 300; }
    .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4::-webkit-scrollbar {
      width: 6px; }
    .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4::-webkit-scrollbar-thumb {
      background: #ddd;
      background: var(--gray-light); }
    .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4::-webkit-scrollbar-track {
      background: transparent; }
    .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4.main_inactive__203PE:before {
      content: "";
      background-color: #f5f5f5;
      background-color: var(--bubble-bg);
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 10; }
    .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_Bloqueo__18o7w {
      opacity: 0;
      transition: all 0.3s linear;
      height: 0;
      background-color: #666;
      background-color: var(--gray);
      font-weight: 100;
      position: fixed;
      top: 7rem;
      left: 0px;
      width: 100%;
      z-index: 100;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      color: #fff;
      color: var(--bg-back); }
      .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_Bloqueo__18o7w.main_HelpBloqued__2hPu7 {
        opacity: 1;
        height: auto; }
    .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_Subtittle__3Srdy {
      font-weight: 400;
      margin: 1rem 0rem 2rem 0rem;
      color: #fff; }
    .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_IconLi__299aS {
      height: 1rem;
      width: 2rem;
      margin-right: 1.1rem; }
      .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_IconLi__299aS path {
        fill: #2979ff;
        fill: var(--first); }
    .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC {
      cursor: pointer;
      background: #f2f2f2;
      border-radius: 0.5rem;
      flex-flow: column;
      margin: 0 0rem 0.5rem; }
      .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC:last-child {
        margin-bottom: 25px; }
      .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC .main_AssistantTitle__pxNBR {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 0px;
        transition: all 0.35s ease-in-out; }
        .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC .main_AssistantTitle__pxNBR:hover {
          background-color: #fff; }
          .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC .main_AssistantTitle__pxNBR:hover a,
          .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC .main_AssistantTitle__pxNBR:hover i {
            color: #2979ff;
            color: var(--gradient-medium); }
        .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC .main_AssistantTitle__pxNBR:last-child, .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC .main_AssistantTitle__pxNBR:first-child {
          border-radius: 5px; }
      .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC a {
        font-weight: 680;
        padding-left: 1.5rem;
        color: #2979ff;
        color: var(--first);
        font-size: 1.3rem;
        text-decoration: none; }
        .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC a:hover {
          color: #2979ff;
          color: var(--gradient-medium); }
      .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC .main_LiChild__THzY8 {
        border-bottom: 1px solid #dadada;
        padding: 1rem 0px;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC .main_LiChild__THzY8 a {
          padding-left: 20px; }
        .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC .main_LiChild__THzY8:first-child {
          border-top: 1px solid #dadada; }
        .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC .main_LiChild__THzY8:last-child {
          border-radius: 0px 0px 5px 5px;
          border-bottom: none; }
        .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC .main_LiChild__THzY8:hover {
          background-color: #fff;
          color: #2979ff;
          color: var(--first); }
          .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC .main_LiChild__THzY8:hover a {
            color: #2979ff;
            color: var(--gradient-medium); }
      .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC .main_NodoAbuelo__1-pf1 {
        max-height: 0;
        transition: max-height 0.3 ease-out;
        overflow: hidden; }
        .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC .main_NodoAbuelo__1-pf1.main_Active__3mnli {
          max-height: 500px;
          transition: max-height 0.3s ease-in; }
        .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC .main_NodoAbuelo__1-pf1 .main_LiChild__THzY8 a {
          padding-left: 20px; }
        .main_MainAssistant__3dmqT .main_AssistantHelper__3_2L4 .main_AssistantHelperChild__2BhLC .main_NodoAbuelo__1-pf1 p {
          font-size: 13px;
          padding: 5px 15px 10px 15px;
          margin-bottom: 0; }
  .main_MainAssistant__3dmqT .main_fade-show__gg2Io {
    height: calc(100% + 0.1rem);
    grid-area: conversation;
    grid-row: 2 / 3;
    overflow: hidden;
    overflow-y: scroll;
    background: #fff;
    transition: all 300ms ease-in-out;
    grid-column: 1 / 3;
    background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0) 50%, black 100%);
    padding: 1.6rem 0.8rem 1.6rem 1.6rem; }
  .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr {
    height: calc(100% + 0.1rem);
    grid-area: conversation;
    grid-row: 2 / 3;
    overflow: hidden;
    overflow-y: scroll;
    background: white;
    transition: all 300ms ease-in-out;
    grid-column: 1 / 3;
    padding: 1.6rem 0.8rem 1.6rem 1.6rem; }
    .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr.main_holder__12rRk {
      height: calc(100vh - 5rem);
      opacity: 0.3; }
    .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr::-webkit-scrollbar {
      width: 6px; }
    .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr::-webkit-scrollbar-thumb {
      background: #ddd;
      background: var(--gray-light); }
    .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr::-webkit-scrollbar-track {
      background: transparent; }
    .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr.main_Active__3mnli {
      height: calc(50vh - 4rem);
      margin-top: calc(50vh - 4rem); }
    .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr.main_Show__2AW4K {
      z-index: 1;
      background: rgba(0, 0, 0, 0.35);
      opacity: 0.9;
      z-index: 1; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr.main_Show__2AW4K .main_Bubble__3evnX {
        z-index: 0;
        display: none; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr.main_Show__2AW4K .main_ConversationBubble__1pqIm {
        z-index: 0; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr.main_Show__2AW4K .main_ConversationBubble__1pqIm img {
          display: none; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr.main_Show__2AW4K .main_ConversationBubbleForm__3_72A img {
        display: none; }
    .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm {
      margin-bottom: 0.8rem;
      display: flex;
      width: 100%;
      align-items: flex-end;
      justify-content: flex-start;
      position: relative;
      flex-wrap: wrap;
      z-index: 0; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm img {
        width: 3.8rem;
        margin: 0 !important;
        border: none;
        align-self: flex-end;
        object-fit: cover; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Bubble__3evnX {
        background-color: #ebebeb;
        padding: 1.2rem 1.6rem;
        font-weight: 500;
        color: #6e6b6a;
        word-break: break-word;
        border-radius: 15px 15px 0 15px;
        margin-left: 0;
        max-width: calc(100% - 3.8rem);
        margin-top: 0.5rem; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Bubble__3evnX ul,
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Bubble__3evnX ol {
          margin: 1.6rem; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Bubble__3evnX img {
          width: auto !important;
          object-fit: unset !important; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm.main_To__1Fxwa {
        justify-content: flex-end; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm.main_To__1Fxwa .main_Bubble__3evnX {
          background-color: #2979ff;
          background-color: var(--first);
          color: #FFFFFF;
          color: var(--bubble-bg-message-text-send); }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm.main_Buttons__1Ra-8 {
        justify-content: center;
        margin-bottom: 0rem; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm.main_Buttons__1Ra-8 .main_Btn__1VyIx {
          margin-bottom: 0.8rem; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm.main_Datepickers__1dWFX {
        justify-content: space-between; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Assessment__3Wha4 {
        display: block;
        padding: 0.5rem 1.5rem;
        background: rgba(0, 0, 0, 0.1);
        margin: 0.8rem -1.5rem -1.5rem -1.5rem;
        border-radius: 0 0 3rem 3rem; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Assessment__3Wha4 a {
          padding: 0.8rem;
          display: inline-block;
          text-decoration: none;
          font-weight: normal; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_BubbleFile__3rm5w {
        width: 100%;
        border-radius: 0.8rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
        padding: 0.8rem;
        margin-bottom: 0.8rem;
        align-items: center;
        background-color: #2979ff;
        background-color: var(--first);
        font-size: 1.4rem; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_BubbleFile__3rm5w.main_Img__tENzF {
          padding: 0; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_BubbleFile__3rm5w.main_Img__tENzF img {
            width: 100%; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_BubbleFile__3rm5w i {
          margin-left: 0.4rem;
          color: #fff;
          color: var(--bg-back); }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_BubbleFile__3rm5w a {
          color: #fff; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_BubbleFile__3rm5w a:hover {
            color: #ddd;
            color: var(--gray-light); }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm.main_Attach__mdMJ6 {
        justify-content: center; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm.main_Attach__mdMJ6 .main_BtnAttach__f9u0C {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 200px;
          padding: 0.8rem;
          font-size: 1.4rem;
          background: #fff;
          background: var(--bg-back);
          color: #2979ff;
          color: var(--first); }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm.main_Attach__mdMJ6 .main_BtnAttach__f9u0C i {
            margin-left: 1rem; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm.main_Attach__mdMJ6 .main_BtnAttach__f9u0C input {
            display: none; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm.main_Attach__mdMJ6 .main_BtnAttach__f9u0C:hover {
            background: #2979ff;
            background: var(--first);
            color: #fff;
            color: var(--bg-back); }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm.main_Attach__mdMJ6 p.main_Error__3-jxp {
          color: #dd2c00;
          color: var(--error);
          font-size: 1.4rem;
          margin-top: 0.5rem; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Select__BD_CK {
        width: 100%;
        position: relative; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Select__BD_CK:before {
          content: "";
          position: absolute;
          top: 1.4rem;
          right: 1.5rem;
          width: 0;
          height: 0;
          border-left: 0.8rem solid transparent;
          border-right: 0.8rem solid transparent;
          border-top: 1rem solid #999;
          border-top: 1rem solid var(--gray-dark);
          z-index: 11; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Select__BD_CK .main_Options__1eIv0 {
          width: 100%;
          margin: 0;
          height: 3.5rem;
          background: transparent;
          color: #999;
          color: var(--gray-dark);
          font-weight: 300;
          overflow: hidden;
          display: flex;
          flex-flow: column;
          font-size: 1.4rem; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Select__BD_CK .main_Options__1eIv0.main_Active__3mnli {
            overflow: initial; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Select__BD_CK .main_Options__1eIv0.main_Active__3mnli div:first-child {
              border-radius: 0.4rem 0.4rem 0px 0px; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Select__BD_CK .main_Options__1eIv0.main_Active__3mnli.main_Error__3-jxp div:first-child {
              border-radius: 0.4rem 0.4rem 0px 0px; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Select__BD_CK .main_Options__1eIv0.main_Error__3-jxp div:first-child {
            border-radius: 0.4rem;
            border: 0.1rem solid;
            border-color: #dd2c00;
            border-color: var(--error);
            color: #dd2c00;
            color: var(--error); }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Select__BD_CK .main_Options__1eIv0 div {
            cursor: pointer;
            padding: 0.6rem 1.8rem;
            z-index: 10;
            background: #fff;
            background: var(--bg-back);
            position: relative;
            border-right: 0.1rem solid #E2E2E2;
            border-right: 0.1rem solid var(--border-color);
            border-bottom: 0.1rem solid #E2E2E2;
            border-bottom: 0.1rem solid var(--border-color);
            border-left: 0.1rem solid #E2E2E2;
            border-left: 0.1rem solid var(--border-color); }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Select__BD_CK .main_Options__1eIv0 div:hover {
              background: #f8f8f8;
              background: var(--white-gray); }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Select__BD_CK .main_Options__1eIv0 div:first-child {
              border-radius: 0.4rem;
              border: 0.1rem solid #E2E2E2;
              border: 0.1rem solid var(--border-color); }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Select__BD_CK .main_Options__1eIv0 div:last-child {
              border-bottom: 0.1rem solid #E2E2E2;
              border-bottom: 0.1rem solid var(--border-color);
              margin-bottom: 2rem;
              border-radius: 0px 0px 0.4rem 0.4rem; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Select__BD_CK .main_Options__1eIv0 div.main_Disabled__6OnfT {
              background: #ddd;
              background: var(--gray-light);
              opacity: 1; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm button.main_Hover__6l4vG {
        background: #2979ff !important;
        background: var(--gradient-medium) !important;
        color: #fff;
        color: var(--bg-back); }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_MultiButtons__1r9Mf {
        display: flex;
        width: 100%;
        justify-content: flex-end; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_MultiButtons__1r9Mf button {
          margin-top: 0.8rem; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_MultiButtons__1r9Mf button i {
            margin-left: 0.8rem; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_react-datepicker-popper__j-K5M {
        z-index: 10; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_SingleDate__3d7Pj {
        width: 50%; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_SingleDate__3d7Pj label {
          font-size: 1.3rem;
          margin-bottom: 0rem; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_DoubleDate__1y8qA {
        width: 48%; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_DoubleDate__1y8qA label {
          font-size: 1.3rem;
          margin-bottom: 0rem; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Day__3XC9w {
        width: 2.2rem;
        line-height: 2.2rem;
        font-size: 1.2rem; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Calendar__1iouX .main_react-datepicker__day-name__3eaw3,
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Calendar__1iouX .main_react-datepicker__day__24O_j,
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_Calendar__1iouX .main_react-datepicker__time-name__1TDGX {
        width: 2.2rem;
        line-height: 2.2rem;
        font-size: 1.2rem; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_DatepickerCognitive__26fWb {
        margin: 0;
        height: 3.6rem;
        background: transparent;
        padding: 0 1.8rem;
        border: 0.1rem solid #999;
        border: 0.1rem solid var(--gray-dark);
        color: white;
        resize: none;
        font-weight: 300;
        width: 100%;
        border-radius: 1.8rem; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_DatepickerCognitive__26fWb:-webkit-autofill, .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_DatepickerCognitive__26fWb:-webkit-autofill:hover, .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_DatepickerCognitive__26fWb:-webkit-autofill:focus, .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_DatepickerCognitive__26fWb:-webkit-autofill:active {
          -webkit-text-fill-color: #666 !important;
          -webkit-text-fill-color: var(--gray) !important;
          -webkit-transition: background-color 5000s ease-in-out 0s;
          transition: background-color 5000s ease-in-out 0s; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_DatepickerCognitive__26fWb::-webkit-input-placeholder {
          color: white;
          font-weight: 300; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_DatepickerCognitive__26fWb::-moz-placeholder {
          color: white;
          font-weight: 300; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_DatepickerCognitive__26fWb:-ms-input-placeholder {
          color: white;
          font-weight: 300; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_DatepickerCognitive__26fWb:-moz-placeholder {
          color: white;
          font-weight: 300; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubble__1pqIm .main_DatepickerCognitive__26fWb.main_Error__3-jxp {
          border-color: #dd2c00;
          border-color: var(--error);
          color: #dd2c00;
          color: var(--error); }
    .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubbleForm__3_72A {
      margin-bottom: 0.8rem;
      display: flex;
      width: 100%;
      align-items: flex-end;
      justify-content: flex-start;
      position: relative;
      flex-wrap: column;
      z-index: 0; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ConversationBubbleForm__3_72A img {
        width: 3rem;
        border: none;
        align-self: flex-end; }
    .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_Likes__1B0_1 {
      background-color: #f5f5f5;
      background-color: var(--bubble-bg);
      padding: 1.2rem 1.6rem;
      font-size: 1.4rem;
      color: #9a9a9f;
      color: var(--bubble-bg-message-text);
      word-break: break-word;
      border-radius: 0.5rem;
      margin-left: 0.8rem;
      display: flex;
      align-items: center;
      margin-top: 0.8rem;
      justify-content: space-between; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_Likes__1B0_1 .main_LikeDiv__34m0v {
        display: flex; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_Likes__1B0_1 .main_LikeDiv__34m0v button {
          color: #fff;
          color: var(--bg-back);
          border-color: #fff;
          border-color: var(--bg-back); }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_Likes__1B0_1 .main_LikeDiv__34m0v button:first-child {
            background: #dd2c00;
            background: var(--error); }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_Likes__1B0_1 .main_LikeDiv__34m0v button:first-child:hover {
              background: #aa2200; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_Likes__1B0_1 .main_LikeDiv__34m0v button:last-child {
            background: #86c546;
            background: var(--success); }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_Likes__1B0_1 .main_LikeDiv__34m0v button:last-child:hover {
              background: #6ca434; }
    .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_Bloqued__3mlTB .main_Btn__1VyIx {
      opacity: 0.2; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_Bloqued__3mlTB .main_Btn__1VyIx:hover {
        box-shadow: 0 0 15px rgba(247, 247, 247, 0.7); }
    .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_Bloqued__3mlTB:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 3;
      background: rgba(0, 0, 0, 0); }
    .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_Bloqued__3mlTB .main_Select__BD_CK:after {
      content: "";
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: 0;
      cursor: not-allowed;
      background: #f8f8f8;
      background: var(--white-gray);
      border-radius: 0.4rem;
      opacity: 0.6; }
    .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_Bloqued__3mlTB .main_Select__BD_CK:before {
      z-index: 0; }
    .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_Bloqued__3mlTB .main_Select__BD_CK .main_Options__1eIv0 div {
      z-index: 0; }
    .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI {
      box-sizing: border-box;
      background: white;
      border-radius: 0.9rem;
      top: 1px;
      z-index: 3;
      opacity: 2;
      border-top: 0.2rem solid #2979ff;
      border-top: 0.2rem solid var(--first);
      box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
      margin-bottom: 35%;
      width: 100%; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI.main_ContainerValoracion__KRmng {
        padding-bottom: 1.6rem; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI.main_ContainerValoracion__KRmng form fieldset,
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI.main_ContainerValoracion__KRmng form .main_HeaderForm__3xcSz {
          margin-bottom: 0.8rem; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI.main_ContainerValoracion__KRmng form fieldset legend,
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI.main_ContainerValoracion__KRmng form .main_HeaderForm__3xcSz legend {
            text-transform: none; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI.main_ContainerValoracion__KRmng form button {
          width: 100%; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 3; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_Red__1QIxu {
          text-align: center;
          color: #dd2c00;
          color: var(--error);
          margin-bottom: 0.8rem;
          font-size: 1.2rem; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_HeaderForm__3xcSz {
          padding: 1.6rem 1.6rem 0.8rem 1.6rem;
          color: #9a9a9f;
          color: var(--bubble-bg-message-text);
          display: flex;
          background: #f42534;
          z-index: 3; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_HeaderForm__3xcSz p {
            margin: 0;
            color: white;
            font-size: 1.4rem;
            font-weight: 500;
            text-align: center;
            z-index: 3; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_HeaderForm__3xcSz p.main_title__2cg8E {
              margin-bottom: 5px;
              font-weight: bold;
              font-size: 14px;
              z-index: 3; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_HeaderForm__3xcSz .main_Icon__27UnT {
            color: white;
            margin-right: 1.6rem;
            width: 6rem; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_HeaderForm__3xcSz i {
            color: white;
            margin-right: 1.6rem;
            width: 6rem; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_HeaderForm__3xcSz p.main_Red__1QIxu {
            font-size: 1.3rem; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_HeaderForm__3xcSz .main_CloseForm__2TjD- {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 14px; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_HeaderForm__3xcSz .main_CloseForm__2TjD- button,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_HeaderForm__3xcSz .main_CloseForm__2TjD- button:focus {
              width: 32px;
              height: 32px;
              padding: 0 9px;
              background: none;
              border: none;
              outline: 0; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_bkg-gray__u4hdE {
          margin: 0 -32px 16px -32px;
          padding: 0px 32px 16px 32px;
          height: auto;
          opacity: 1;
          transition: opacity 300ms ease-in-out; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_bkg-gray__u4hdE fieldset legend {
            line-height: 1rem;
            text-align: center; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_bkg-gray__u4hdE fieldset textarea#main_por-que__oj_2n {
            margin-top: 10px; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_bkg-gray__u4hdE.main_inactive__203PE {
            height: 0px;
            opacity: 0;
            padding: 0; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset {
          padding: 0rem 1.6rem 0.4rem 1.6rem;
          position: relative;
          width: 100%; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset input {
            transition: all 300ms ease; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset ul {
            margin-top: 10px;
            width: 100%;
            padding: 10px;
            background-color: #f8f8f8;
            background-color: var(--white-gray);
            border: 1px solid #E2E2E2;
            border: 1px solid var(--border-color); }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset ul li {
              list-style: none;
              width: 100%;
              justify-content: space-between;
              display: flex;
              align-items: center; }
              .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset ul li i {
                cursor: pointer; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset.main_Error__3-jxp input {
            border: 1px solid #dd2c00;
            border: 1px solid var(--error); }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset.main_Error__3-jxp legend {
            color: #dd2c00;
            color: var(--error); }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset small.main_Error__3-jxp {
            color: #dd2c00;
            color: var(--error);
            font-size: 1.2rem; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset legend {
            color: black;
            font-weight: 700;
            text-transform: capitalize;
            font-size: 1.2rem;
            transition: all 300ms ease; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_link__1xETP {
            color: black;
            font-weight: 700;
            text-transform: capitalize;
            font-size: 1.2rem;
            transition: all 300ms ease; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Switch__2k02F {
            position: relative;
            width: 100%;
            height: 3.4rem;
            margin-bottom: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #9a9a9f;
            color: var(--bubble-bg-message-text); }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Switch__2k02F input {
            display: none; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Slider__378rm {
            cursor: pointer;
            width: 6.5rem;
            height: 100%;
            background-color: #ccc;
            transition: 0.4s;
            margin: 0px 1rem;
            position: relative;
            /* Rounded sliders */ }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Slider__378rm:before {
              position: absolute;
              content: "";
              height: 2.6rem;
              width: 2.6rem;
              left: 0.4rem;
              bottom: 0.4rem;
              background-color: white;
              transition: 0.4s; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Slider__378rm.main_Round__24RIo {
              border-radius: 3.4rem; }
              .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Slider__378rm.main_Round__24RIo:before {
                border-radius: 50%; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset input:checked + .main_Slider__378rm {
            background-color: #2979ff;
            background-color: var(--first); }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset input:focus + .main_Slider__378rm {
            box-shadow: 0 0 0.1rem #2979ff;
            box-shadow: 0 0 0.1rem var(--first); }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset input:checked + .main_Slider__378rm:before {
            -webkit-transform: translateX(3rem);
                    transform: translateX(3rem); }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset input,
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset select,
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset textarea {
            margin: 0;
            height: 3.6rem;
            padding: 0 1.8rem;
            border: 1px solid grey;
            color: #666;
            color: var(--gray);
            resize: initial;
            font-weight: 400;
            font-size: 1.3rem;
            border-radius: 2rem; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset input:-webkit-autofill, .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset input:-webkit-autofill:hover, .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset input:-webkit-autofill:focus, .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset input:-webkit-autofill:active,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset select:-webkit-autofill,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset select:-webkit-autofill:hover,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset select:-webkit-autofill:focus,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset select:-webkit-autofill:active,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset textarea:-webkit-autofill,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset textarea:-webkit-autofill:hover,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset textarea:-webkit-autofill:focus,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset textarea:-webkit-autofill:active {
              -webkit-text-fill-color: #666 !important;
              -webkit-text-fill-color: var(--gray) !important;
              -webkit-transition: background-color 5000s ease-in-out 0s;
              transition: background-color 5000s ease-in-out 0s; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset input::-webkit-input-placeholder,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset select::-webkit-input-placeholder,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset textarea::-webkit-input-placeholder {
              color: #ccc;
              font-weight: 300; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset input::-moz-placeholder,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset select::-moz-placeholder,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset textarea::-moz-placeholder {
              color: #ccc;
              font-weight: 300; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset input:-ms-input-placeholder,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset select:-ms-input-placeholder,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset textarea:-ms-input-placeholder {
              color: #ccc;
              font-weight: 300; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset input:-moz-placeholder,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset select:-moz-placeholder,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset textarea:-moz-placeholder {
              color: #ccc;
              font-weight: 300; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset input.main_Error__3-jxp,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset select.main_Error__3-jxp,
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset textarea.main_Error__3-jxp {
              border-color: #dd2c00;
              border-color: var(--error);
              color: #dd2c00;
              color: var(--error); }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset select {
            cursor: pointer;
            display: none; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Select__BD_CK {
            width: 100%;
            position: relative; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Select__BD_CK:before {
              content: "";
              position: absolute;
              top: 1.4rem;
              right: 1.5rem;
              width: 0;
              height: 0;
              border-left: 0.8rem solid transparent;
              border-right: 0.8rem solid transparent;
              border-top: 1rem solid #999;
              border-top: 1rem solid var(--gray-dark);
              z-index: 11; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Select__BD_CK .main_Options__1eIv0 {
              width: 100%;
              margin: 0;
              height: 3.5rem;
              background: transparent;
              color: #999;
              color: var(--gray-dark);
              font-weight: 300;
              overflow: hidden;
              display: flex;
              flex-flow: column;
              font-size: 1.4rem; }
              .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Select__BD_CK .main_Options__1eIv0.main_Active__3mnli {
                overflow: initial; }
                .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Select__BD_CK .main_Options__1eIv0.main_Active__3mnli div:first-child {
                  border-radius: 0.4rem 0.4rem 0px 0px; }
                .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Select__BD_CK .main_Options__1eIv0.main_Active__3mnli.main_Error__3-jxp div:first-child {
                  border-radius: 0.4rem 0.4rem 0px 0px; }
              .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Select__BD_CK .main_Options__1eIv0.main_Error__3-jxp div:first-child {
                border-radius: 0.4rem;
                border: 0.1rem solid;
                border-color: #dd2c00;
                border-color: var(--error);
                color: #dd2c00;
                color: var(--error); }
              .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Select__BD_CK .main_Options__1eIv0 div {
                cursor: pointer;
                padding: 0.6rem 1.8rem;
                z-index: 10;
                background: #fff;
                background: var(--bg-back);
                position: relative;
                border-right: 0.1rem solid #E2E2E2;
                border-right: 0.1rem solid var(--border-color);
                border-bottom: 0.1rem solid #E2E2E2;
                border-bottom: 0.1rem solid var(--border-color);
                border-left: 0.1rem solid #E2E2E2;
                border-left: 0.1rem solid var(--border-color); }
                .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Select__BD_CK .main_Options__1eIv0 div:hover {
                  background: #f8f8f8;
                  background: var(--white-gray); }
                .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Select__BD_CK .main_Options__1eIv0 div:first-child {
                  border-radius: 0.4rem;
                  border: 0.1rem solid #E2E2E2;
                  border: 0.1rem solid var(--border-color); }
                .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Select__BD_CK .main_Options__1eIv0 div:last-child {
                  border-bottom: 0.1rem solid #E2E2E2;
                  border-bottom: 0.1rem solid var(--border-color);
                  margin-bottom: 2rem;
                  border-radius: 0px 0px 0.4rem 0.4rem; }
                .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_Select__BD_CK .main_Options__1eIv0 div.main_Disabled__6OnfT {
                  background: #ddd;
                  background: var(--gray-light);
                  opacity: 1; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_SelectSearch__2bt9f .main_Select__BD_CK {
            padding-right: 2.3rem !important; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_SelectSearch__2bt9f .main_Select__BD_CK.main_Active__3mnli {
              border-radius: 0.5rem 0.5rem 0px 0px; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_SelectSearch__2bt9f .main_CloseSelect__1pUIO:before {
            content: "";
            position: absolute;
            top: 32px;
            right: 30px;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 10px solid #999;
            border-bottom: 10px solid var(--gray-dark);
            z-index: 13;
            background-color: white;
            cursor: pointer; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_SelectSearch__2bt9f:before {
            content: "";
            position: absolute;
            top: 32px;
            right: 30px;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 10px solid #999;
            border-top: 10px solid var(--gray-dark);
            z-index: 9; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_SelectSearch__2bt9f .main_Options__1eIv0 {
            margin: 0;
            height: 36px;
            background: transparent;
            color: #666;
            font-weight: 300;
            -webkit-border-radius: 18px;
            overflow: hidden;
            display: flex;
            flex-flow: column; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_SelectSearch__2bt9f .main_Options__1eIv0.main_Active__3mnli {
              max-height: 200px;
              height: 100%;
              border-radius: initial;
              overflow-y: scroll; }
              .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_SelectSearch__2bt9f .main_Options__1eIv0.main_Active__3mnli::-webkit-scrollbar {
                width: 6px; }
              .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_SelectSearch__2bt9f .main_Options__1eIv0.main_Active__3mnli::-webkit-scrollbar-thumb {
                background: #adadad; }
              .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_SelectSearch__2bt9f .main_Options__1eIv0.main_Active__3mnli::-webkit-scrollbar-track {
                background: #E2E2E2;
                background: var(--border-color); }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_SelectSearch__2bt9f .main_Options__1eIv0.main_Error__3-jxp div:first-child {
              border-radius: 18px;
              border: 1px solid;
              border-color: #d9534f;
              color: #d9534f; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_SelectSearch__2bt9f .main_Options__1eIv0 div {
              cursor: pointer;
              padding: 8px 17px;
              font-size: 1.2rem;
              z-index: 10;
              background: #fcfcfc;
              position: relative;
              border-right: 1px solid #E2E2E2;
              border-right: 1px solid var(--border-color);
              border-bottom: 1px solid #E2E2E2;
              border-bottom: 1px solid var(--border-color);
              border-left: 1px solid #E2E2E2;
              border-left: 1px solid var(--border-color); }
              .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_SelectSearch__2bt9f .main_Options__1eIv0 div:hover {
                background: #eee; }
              .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset .main_SelectSearch__2bt9f .main_Options__1eIv0 div.main_Disabled__6OnfT {
                background: #d0d0d0;
                opacity: 1; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset textarea {
            padding: 0.8rem;
            min-height: initial;
            height: auto;
            background: #fff; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form fieldset.main_Radios__ig2jQ legend {
            font-size: 1.2rem;
            font-weight: 100;
            margin: 0.8rem 0; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_RatingStars__B9Kt3 {
          display: block;
          position: relative; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_RatingStars__B9Kt3 legend {
            font-weight: 100;
            margin-bottom: 1rem; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_RatingStars__B9Kt3:after, .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_RatingStars__B9Kt3:before {
            content: "";
            display: table;
            clear: both; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_RatingStars__B9Kt3 a {
            color: #9a9a9a;
            display: inline-block;
            font-size: 2rem;
            font-weight: normal;
            text-align: center;
            padding-right: 0.8rem;
            transition: all 0.3s linear; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_RatingStars__B9Kt3 a:hover ~ a {
              color: #d3d4d3; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_RatingStars__B9Kt3 a.main_ActiveStar__2nU_j {
              color: #2979ff;
              color: var(--first); }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_RatingStars__B9Kt3 .main_StarValue__24vsP {
            border-top: 2px solid #2979ff;
            border-top: 2px solid var(--first);
            padding: 0.8rem 0 0 0;
            margin-top: 1rem;
            color: #2979ff;
            color: var(--first);
            font-size: 1.2rem; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_RatingStars__B9Kt3 .main_StarValue__24vsP p {
              -webkit-animation: main_inAssistant__InHWm 0.6s linear;
                      animation: main_inAssistant__InHWm 0.6s linear; }
              .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_RatingStars__B9Kt3 .main_StarValue__24vsP p span {
                font-size: 1.6rem; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_Round__24RIo {
          margin-bottom: 0.8rem;
          font-size: 1.4rem;
          font-weight: 600;
          color: #9a9a9f;
          color: var(--bubble-bg-message-text);
          display: flex;
          align-items: center; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_Round__24RIo .main_Circle__1kqtL {
            width: 1.4rem;
            height: 1.4rem;
            border-radius: 50%;
            border: 0.2rem solid #2979ff;
            border: 0.2rem solid var(--first);
            transition: all 300ms ease;
            cursor: pointer;
            margin-right: 1rem; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_Round__24RIo .main_Circle__1kqtL:hover {
              position: relative; }
              .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_Round__24RIo .main_Circle__1kqtL:hover:before {
                content: "";
                width: 0.6rem;
                height: 0.6rem;
                background: #2979ff;
                background: var(--first);
                position: absolute;
                left: 0.2rem;
                top: 0.2rem;
                border-radius: 50%; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_Round__24RIo .main_Circle__1kqtL.main_Active__3mnli {
              position: relative; }
              .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_Round__24RIo .main_Circle__1kqtL.main_Active__3mnli:before {
                content: "";
                width: 0.6rem;
                height: 0.6rem;
                background: #2979ff;
                background: var(--first);
                position: absolute;
                left: 0.2rem;
                top: 0.2rem;
                border-radius: 50%; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_Round__24RIo input {
            visibility: hidden;
            height: 0;
            width: 0;
            overflow: hidden;
            padding: 0; }
            .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_Round__24RIo input:checked + div {
              background: #dd2c00;
              background: var(--error); }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form button,
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form button:focus,
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form button:active,
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form input[type="submit"],
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form input[type="submit"]:focus,
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form input[type="submit"]:active {
          font-size: 1.4rem;
          color: #2979ff;
          color: var(--first);
          height: 3.6rem;
          border-radius: 0.4rem;
          font-weight: 700;
          border: 1px solid #2979ff;
          border: 1px solid var(--first);
          outline: 0;
          transition: all 300ms ease;
          background: transparent;
          padding: 0 20px;
          margin: 0 auto; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form button:hover,
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form button:focus:hover,
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form button:active:hover,
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form input[type="submit"]:hover,
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form input[type="submit"]:focus:hover,
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form input[type="submit"]:active:hover {
            background: #2979ff;
            background: var(--gradient-medium);
            color: #fff;
            color: var(--bg-back); }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form button.main_ButtonSend__3bj-y,
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form button:focus.main_ButtonSend__3bj-y,
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form button:active.main_ButtonSend__3bj-y,
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form input[type="submit"].main_ButtonSend__3bj-y,
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form input[type="submit"]:focus.main_ButtonSend__3bj-y,
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form input[type="submit"]:active.main_ButtonSend__3bj-y {
            margin: 1.8rem 0;
            border-radius: 18px;
            width: 20rem;
            background-color: red;
            color: white; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form button:disabled {
          opacity: 0.3; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form button:disabled:hover {
            background: transparent;
            color: #2979ff;
            color: var(--first); }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_spinner-holder__1pl0U {
          height: auto;
          left: 0;
          bottom: 0;
          border-radius: 6px;
          -moz-border-radius: 6px;
          background: #fff; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI form .main_spinner-holder__1pl0U.main_show__17xBC {
            display: block; }
      .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI .main_ErrorMsg__1Nhss {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 16px;
        text-align: center;
        color: #fff;
        color: var(--bg-back);
        background: #dd2c00;
        background: var(--error);
        font-weight: 300;
        z-index: 2;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        box-shadow: 0px 0 25px rgba(0, 0, 0, 0.5);
        transition: all 300ms ease; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI .main_ErrorMsg__1Nhss p {
          margin-bottom: 5px; }
          .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI .main_ErrorMsg__1Nhss p:last-child {
            margin-bottom: 0; }
        .main_MainAssistant__3dmqT .main_ConversationHolder__JXHjr .main_ContainerForm__1H9dI .main_ErrorMsg__1Nhss.main_Show__2AW4K {
          -webkit-transform: translateY(0);
          transform: translateY(0); }
  .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY {
    grid-area: input;
    grid-column: 1 / 3;
    transition: all 300ms ease;
    background: white;
    opacity: 1;
    display: flex;
    border-top: 1px solid #E2E2E2;
    border-top: 1px solid var(--border-color);
    color: white;
    background-color: white; }
    .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY.main_Inactive__2QfeS {
      background-color: white;
      z-index: 0; }
    .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUser__2RvLw {
      margin-bottom: 0;
      height: 5.6rem;
      background-color: white;
      font-weight: 400;
      font-size: 1.4rem;
      padding: 0;
      letter-spacing: 0.05rem;
      margin-left: 1rem;
      width: 100%;
      color: white; }
      .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUser__2RvLw::placeholder, .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUser__2RvLw:-ms-input-placeholder {
        color: white !important; }
    .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_example__sRb71 {
      background-image: url(/static/media/icono-cognitiva-2.fa1178b4.svg); }
    .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_ButtonHelp__V220W {
      background-image: url(/static/media/icon-help-grey.ef8e0908.svg);
      width: 3.6rem;
      height: 3.6rem;
      background-position: 0.6rem 0.5rem;
      background-size: 2.3rem;
      background-repeat: space; }
      .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_ButtonHelp__V220W.main_active__2PAgr {
        opacity: 0.8; }
    .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq {
      padding: 5px !important;
      position: relative; }
      .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq.main_Emoji__eNRT4 {
        margin-right: -0.6rem; }
      .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq i {
        margin: 0; }
      .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq .main_IconVoice__1pvHA,
      .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq .main_IconPlane__2iEbo,
      .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq .main_IconHelp__pCMbJ,
      .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq .main_IconStop__1DVPk,
      .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq .main_IconEmoji__2uBdf {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
        width: 2.7rem;
        height: 2.7rem;
        display: block;
        transition: all 0.3s ease-out;
        -webkit-animation: main_inAssistant__InHWm 0.4s linear;
                animation: main_inAssistant__InHWm 0.4s linear; }
        .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq .main_IconVoice__1pvHA:hover,
        .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq .main_IconPlane__2iEbo:hover,
        .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq .main_IconHelp__pCMbJ:hover,
        .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq .main_IconStop__1DVPk:hover,
        .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq .main_IconEmoji__2uBdf:hover {
          opacity: 2; }
      .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq .main_IconEmoji__2uBdf {
        background-image: url(/static/media/icon-emoji.bad98bdb.svg);
        width: 29px;
        height: 29px; }
      .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq .main_IconVoice__1pvHA {
        background-image: url(/static/media/icon-microphone.1d2c3585.svg);
        width: 28px;
        height: 28px; }
      .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq .main_IconPlane__2iEbo {
        background-image: url(/static/media/icon-send-hover.46640a49.svg); }
      .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq .main_IconHelp__pCMbJ {
        background-image: url(/static/media/icon-help-grey.ef8e0908.svg);
        width: 2.6rem; }
      .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq.main_Active__3mnli:before {
        border-left: 10px solid;
        border-right: 10px solid;
        border-top: 12px solid #2979ff;
        border-top: 12px solid var(--first);
        -webkit-animation: main_inAssistant__InHWm 0.4s linear;
                animation: main_inAssistant__InHWm 0.4s linear; }
      .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq:before {
        content: "";
        transition: border-top 0.3s ease-in-out;
        position: absolute;
        top: -1px;
        height: 0;
        border: none;
        z-index: 1; }
      .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq.main_Transcript__JMnwv {
        display: flex;
        align-items: center;
        justify-content: space-around; }
        .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq.main_Transcript__JMnwv .main_IconStop__1DVPk {
          background-image: url(/static/media/icon-stop.46ed2fd2.svg);
          background-color: #dd2c00;
          background-color: var(--error);
          border-radius: 50%; }
        .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq.main_Transcript__JMnwv .main_IconPlane__2iEbo {
          background-image: url(/static/media/icon-send-rounded.8f6e6193.svg);
          background-color: #86c546;
          background-color: var(--success);
          border-radius: 50%; }
        .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq.main_Transcript__JMnwv .main_Timer__18eR_ {
          min-width: 3.5rem;
          -webkit-animation: main_inAssistant__InHWm 0.4s linear;
                  animation: main_inAssistant__InHWm 0.4s linear; }
          .main_MainAssistant__3dmqT .main_InputUserHolder__11LcY .main_InputUserBtn__3yZZq.main_Transcript__JMnwv .main_Timer__18eR_ p {
            font-weight: 600;
            font-size: 1.4rem; }
  .main_MainAssistant__3dmqT .main_Loader__12QoZ {
    grid-column: 1 / 3;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f7; }
    .main_MainAssistant__3dmqT .main_Loader__12QoZ .main_Dot__1fwHb {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 3px;
      background-color: #2979ff;
      background-color: var(--first);
      -webkit-animation: main_in__3Ptty 1s linear infinite;
              animation: main_in__3Ptty 1s linear infinite; }
      .main_MainAssistant__3dmqT .main_Loader__12QoZ .main_Dot__1fwHb:nth-child(2) {
        -webkit-animation-delay: -0.8s;
                animation-delay: -0.8s; }
      .main_MainAssistant__3dmqT .main_Loader__12QoZ .main_Dot__1fwHb:nth-child(3) {
        -webkit-animation-delay: -0.6s;
                animation-delay: -0.6s; }
  .main_MainAssistant__3dmqT .main_iframe-cognitive-assistant-container-minimized__1f4rJ {
    -webkit-transform: translate3d(0, calc(100vh - 6rem), 0);
            transform: translate3d(0, calc(100vh - 6rem), 0);
    padding: 0.7rem;
    padding-left: 1.4rem;
    height: 6rem; }
  .main_MainAssistant__3dmqT.main_Mobile__AYGce {
    border-radius: 0;
    box-shadow: initial;
    margin: 0;
    grid-template-rows: 6rem calc(100vh - 11.7rem) 5.7rem; }
    .main_MainAssistant__3dmqT.main_Mobile__AYGce .main_HeaderHelpDown__3FvFH {
      grid-template-columns: 6rem auto 3.6rem 3.6rem 0.5rem; }
    .main_MainAssistant__3dmqT.main_Mobile__AYGce .main_HeaderHelpUp__j6Q8z {
      grid-template-columns: 6rem auto 3.6rem 3.6rem 3.6rem 0.5rem; }
    .main_MainAssistant__3dmqT.main_Mobile__AYGce.main_HeaderMore__15yFO {
      height: 100vh; }
      .main_MainAssistant__3dmqT.main_Mobile__AYGce.main_HeaderMore__15yFO .main_HeaderHelpDown__3FvFH {
        grid-template-columns: 2rem auto 3.6rem 3.6rem 2rem; }
      .main_MainAssistant__3dmqT.main_Mobile__AYGce.main_HeaderMore__15yFO .main_HeaderHelpUp__j6Q8z {
        grid-template-columns: 2rem auto 3.6rem 3.6rem 2rem; }
      .main_MainAssistant__3dmqT.main_Mobile__AYGce.main_HeaderMore__15yFO .main_HeaderText__3uYQk {
        margin: 5; }
    .main_MainAssistant__3dmqT.main_Mobile__AYGce .main_Bloqueo__18o7w {
      top: 6rem; }
    .main_MainAssistant__3dmqT.main_Mobile__AYGce .main_LogoCognitiva__1uNm8 {
      display: none; }

.main_SpinnerHolder__3hpL8 {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  height: 100vh;
  left: 0; }
  .main_SpinnerHolder__3hpL8 .main_Spinner__35hfV {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    font-size: 10px;
    position: relative;
    top: calc(50% - 16px);
    margin: 0 auto;
    text-indent: -9999em;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-left: 2px solid #2979ff;
    border-left: 2px solid var(--first);
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation: main_spinner__QtqCQ 0.6s infinite linear;
            animation: main_spinner__QtqCQ 0.6s infinite linear; }

.main_closeFormContainer__3PW8b {
  background: #f42534;
  display: flex;
  width: 100%; }
  .main_closeFormContainer__3PW8b i {
    color: white;
    display: flex;
    margin-left: 45%;
    margin-top: 5%; }

.main_closeFormButton__1JFH6 {
  margin-right: 0 !important;
  color: white !important; }

.boxBubbleLogo {
  width: 100%;
  max-width: 200px;
  margin-right: 0px;
  right: 0;
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 auto; }

.imgBubbleLogo {
  cursor: pointer;
  min-width: 60px;
  max-width: 200px;
  object-fit: cover;
  -webkit-animation: inAssistant 0.4s linear;
          animation: inAssistant 0.4s linear; }

@-webkit-keyframes inAssistant {
  0% {
    opacity: 0; }
  50% {
    opacity: .5; }
  100% {
    opacity: 1; } }

@keyframes inAssistant {
  0% {
    opacity: 0; }
  50% {
    opacity: .5; }
  100% {
    opacity: 1; } }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.2rem;
  line-height: 2.2rem;
  font-size: 1.2rem;
}
.react-datepicker__current-month, .react-datepicker-time__header{
        font-size: 1.2rem;
}
.react-datepicker__navigation{
    border: 0.6rem solid transparent;
}
.react-datepicker__navigation--previous{
    border-right-color: #ccc;
}
.react-datepicker__navigation--next{
       border-left-color: #ccc; 
}
.conversationBubbleForm {
  margin-top: 2.8rem;
  padding: 1.7rem;
  display: flex;
  width: 100%;
  border-radius: 5.1rem;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
  flex-wrap: column; }

.conversationBubbleForm img {
  width: 3rem;
  border: none;
  align-self: flex-end; }

.containerForm {
  box-sizing: border-box;
  background: #fff;
  border-radius: 3.1rem;
  position: fixed;
  z-index: 2;
  top: 16rem;
  right: .1rem;
  box-shadow: 0 0 0.9rem 0 rgba(0, 0, 0, 0.2);
  width: 91%;
  right: 1.5rem;
  padding-bottom: 1.6rem; }

.containerForm .expand {
  width: 80%;
  height: 70%;
  bottom: 10%;
  padding: 0 2px;
  cursor: pointer;
  background: #f7f7f7; }
  .containerForm .expand p {
    height: 33px;
    border: 1.5px solid #999;
    box-shadow: #666;
    border-radius: 18px;
    background: #fff;
    position: relative;
    padding: 0 10px;
    text-align: center;
    line-height: 34px;
    margin-bottom: 1.6rem;
    font-size: 1.5rem;
    font-weight: 400;
    color: grey; }
  .containerForm .expand p.active {
    background-color: var(--first);
    color: white; }
  .containerForm .expand h3 {
    height: 36px;
    margin-top: 1.3rem;
    background: #f7f7f7;
    position: relative;
    padding: 0 10px;
    text-align: center;
    line-height: 34px;
    margin-bottom: 1.6rem;
    font-size: 1.1rem;
    color: black; }

.containerForm form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.containerForm form fieldset {
  padding: 0 1.6rem .4rem;
  position: relative;
  width: 100%; }

.containerForm form fieldset {
  margin-bottom: .8rem; }

.containerForm form fieldset legend {
  color: black;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.1rem;
  transition: all .3s ease; }

.containerForm form fieldset legend {
  text-transform: none; }

.containerForm form fieldset input,
.containerForm form fieldset select,
.containerForm form fieldset textarea {
  margin: 0;
  height: 3.6rem;
  background: transparent;
  padding: 0 1.8rem;
  border: 1px solid #e2e2e2;
  color: #666;
  resize: none;
  font-weight: 300;
  font-size: 2.4rem;
  border-radius: .4rem; }

.containerForm form fieldset input {
  transition: all .3s ease; }

.headerForm {
  padding: 1.6rem 1.6rem .8rem;
  color: white;
  width: 100%;
  border-radius: 1.1rem;
  justify-content: center;
  background-color: var(--first); }

.check {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 1.6rem;
  font-family: Lato,sans-serif;
  text-align: center;
  margin-bottom: 1.4rem; }

.containerForm form fieldset.radios legend {
  font-size: 1.2rem;
  font-weight: 100;
  margin: .8rem 0; }

.containerForm form .round {
  margin-bottom: .8rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #9a9a9f;
  display: flex;
  align-items: center; }

.containerForm form .round .circle {
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  border: .2rem solid #2979ff;
  transition: all .3s ease;
  cursor: pointer;
  margin-right: 1rem; }

.containerForm form .round .circle:hover {
  position: relative; }

.containerForm form .round input {
  visibility: hidden;
  height: 0;
  width: 0;
  overflow: hidden;
  padding: 0; }

.containerForm form .round .circle.active {
  position: relative; }

.containerForm form .round .circle.active:before {
  content: "";
  width: .6rem;
  height: .6rem;
  background: #2979ff;
  background: var(--first);
  position: absolute;
  left: .2rem;
  top: .2rem;
  border-radius: 50%; }

.containerForm form .round .circle:hover:before {
  content: "";
  width: .6rem;
  height: .6rem;
  background: #2979ff;
  position: absolute;
  left: .2rem;
  top: .2rem;
  border-radius: 50%; }

.containerForm form fieldset textarea {
  margin: 0;
  height: 5.6rem;
  background: transparent;
  padding: 0 1.8rem;
  border: 1px solid #e2e2e2;
  color: #666;
  border: 1px solid #999;
  resize: none;
  font-weight: 300;
  font-size: 1.8rem;
  border-radius: 18px; }

.containerForm form fieldset textarea {
  padding: .8rem;
  min-height: 0;
  margin-top: 1.2rem;
  height: 10rem;
  background: #fff; }

.containerForm form button {
  font-size: 1.4rem;
  color: white;
  height: 3.6rem;
  border-radius: 18px;
  font-weight: 700;
  border: 1px solid var(--first);
  outline: 0;
  transition: all .3s ease;
  background: var(--first);
  padding: 0 20px;
  margin: 0 auto;
  width: 100%; }

.containerForm form button:hover {
  color: #fff;
  cursor: pointer; }

.star-rating {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem;
  height: 8rem; }

.emoticons {
  cursor: pointer;
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
  -webkit-filter: drop-shadow(0 0 3px grey);
          filter: drop-shadow(0 0 3px grey);
  font-size: 32px; }

.star.selected {
  cursor: pointer;
  width: 6rem; }

.none {
  display: none; }

.expand-emoji {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 0 3px grey);
          filter: drop-shadow(0 0 3px grey);
  font-size: 43px; }

