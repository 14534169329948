@mixin appearance($val){
    -webkit-appearance: $val;
    -moz-appearance: $val;
    -ms-appearance: $val;
    appearance: $val;
}
@mixin border-radius($radius){
  -webkit-border-radius: $radius;
  border-radius: $radius;
}
