@import url("https://use.fontawesome.com/releases/v5.1.0/css/all.css");
// @font-face {
//     font-family: 'Geomanist';
//     src: url('../fonts/geomanist-light.eot');
//     src: url('../fonts/geomanist-light.eot?#iefix') format('embedded-opentype'), url('../fonts/geomanist-light.woff2') format('woff2'), url('../fonts/geomanist-light.woff') format('woff'), url('../fonts/geomanist-light.ttf') format('truetype'), url('../fonts/geomanist-light.svg#geomanistlight') format('svg');
//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Geomanist';
//     src: url('../fonts/geomanist-book.eot');
//     src: url('../fonts/geomanist-book.eot?#iefix') format('embedded-opentype'), url('../fonts/geomanist-book.woff2') format('woff2'), url('../fonts/geomanist-book.woff') format('woff'), url('../fonts/geomanist-book.ttf') format('truetype'), url('../fonts/geomanist-book.svg#geomanistbook') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Geomanist';
//     src: url('../fonts/geomanist-medium.eot');
//     src: url('../fonts/geomanist-medium.eot?#iefix') format('embedded-opentype'), url('../fonts/geomanist-medium.woff2') format('woff2'), url('../fonts/geomanist-medium.woff') format('woff'), url('../fonts/geomanist-medium.ttf') format('truetype'), url('../fonts/geomanist-medium.svg#geomanistmedium') format('svg');
//     font-weight: 600;
//     font-style: normal;
// }


@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i');

@font-face {
  font-family: 'Open sans';
  src: url('/fonts/opensans-regular-webfont.woff2') format('woff2'),
        url('/fonts/opensans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/opensans-semibold-webfont.woff2') format('woff2'),
        url('/fonts/opensans-semibold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/opensans-light-webfont.woff2') format('woff2'),
        url('/fonts/opensans-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}